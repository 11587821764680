import React, { Component } from 'react';
import * as TweenMax from 'gsap/umd/TweenMax';

// Components
import Loader from '../components/Loader';
import Nav from '../components/Nav';
import Hero from '../components/Hero';
import About from '../components/About';
import Work from '../components/Work';
import Footer from '../components/Footer';

import scrollLock from 'scroll-lock';
import luxy from 'luxy.js';

class Home extends Component {
  state = {
    loading: true,
    loadingArray: [
      'Pretending to load.',
      'Dusting off my best projects for you.',
      'Your patience is much appreciated.',
    ],
  };

  componentDidMount() {
    console.log(TweenMax);

    const _ua = (function (u) {
      return {
        Tablet:
          (u.indexOf('windows') !== -1 &&
            u.indexOf('touch') !== -1 &&
            u.indexOf('tablet pc') === -1) ||
          u.indexOf('ipad') !== -1 ||
          (u.indexOf('android') !== -1 && u.indexOf('mobile') === -1) ||
          (u.indexOf('firefox') !== -1 && u.indexOf('tablet') !== -1) ||
          u.indexOf('kindle') !== -1 ||
          u.indexOf('silk') !== -1 ||
          u.indexOf('playbook') !== -1,
        Mobile:
          (u.indexOf('windows') !== -1 && u.indexOf('phone') !== -1) ||
          u.indexOf('iphone') !== -1 ||
          u.indexOf('ipod') !== -1 ||
          (u.indexOf('android') !== -1 && u.indexOf('mobile') !== -1) ||
          (u.indexOf('firefox') !== -1 && u.indexOf('mobile') !== -1),
      };
    })(window.navigator.userAgent.toLowerCase());

    const luxyEl = document.getElementById('luxy');
    if (luxyEl) {
      if (!_ua.Mobile && !_ua.Tablet) {
        if (window.matchMedia('(max-width: 420px)').matches) return;
        else {
          luxy.init();
        }
      }
    }

    setTimeout(() => {
      document.querySelector('.lazyPortfolio').classList.add('moveUp');
    }, 6400);

    window.addEventListener('load', this.removeLoader);
    if (this.state.loading === true) {
      scrollLock.disablePageScroll();
    }

    this.removeLoader();

    window.addEventListener('scroll', () => {
      const bod = document.querySelector('body');

      // navbar
      const navLogo = document.querySelector('.nav--logo p');
      const navLinks = document.querySelectorAll('.nav--link-item button');
      const topLine = document.querySelector('.burger_menu-top');
      const topBottom = document.querySelector('.burger_menu-bottom');

      // about
      const wTitle = document.querySelectorAll('._w-title');
      const aboutLinks = document.querySelectorAll('.social_links a');
      const aboutShort = document.querySelector('.intro--short');

      // work
      const workTitle = document.querySelectorAll('.item__caption-title');
      const workDesc = document.querySelectorAll('.item__caption-copy');

      // footer
      const headingSecondary = document.querySelectorAll('.o-heading-m');

      if (window.scrollY > window.innerHeight) {
        bod.classList.add('changeColor');
        navLogo.classList.add('darkMode');
        navLinks.forEach((el) => el.classList.add('darkMode'));
        aboutLinks.forEach((el) => el.classList.add('darkMode'));
        aboutShort.classList.add('darkMode');
        wTitle.forEach((el) => el.classList.add('darkMode'));
        workTitle.forEach((el) => el.classList.add('darkMode'));
        workDesc.forEach((el) => el.classList.add('darkMode'));
        headingSecondary.forEach((el) => el.classList.add('darkMode'));
        topLine.classList.add('darkMode');
        topBottom.classList.add('darkMode');
      } else {
        bod.classList.remove('changeColor');
        navLogo.classList.remove('darkMode');
        navLinks.forEach((el) => el.classList.remove('darkMode'));
        aboutLinks.forEach((el) => el.classList.remove('darkMode'));
        aboutShort.classList.remove('darkMode');
        wTitle.forEach((el) => el.classList.remove('darkMode'));
        workTitle.forEach((el) => el.classList.remove('darkMode'));
        workDesc.forEach((el) => el.classList.remove('darkMode'));
        headingSecondary.forEach((el) => el.classList.remove('darkMode'));
        topLine.classList.remove('darkMode');
        topBottom.classList.remove('darkMode');
      }
    });
  }

  removeLoader = () => {
    setTimeout(() => {
      scrollLock.enablePageScroll();
    }, 3800);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 5000);
  };

  closeAlert = () => {
    const modal = document.querySelector('.lazyPortfolio');
    modal.classList.add('removed');
    setTimeout(() => {
      modal.style.display = 'none';
    }, 1000);
  };

  render() {
    return (
      <>
        <Loader
          loadingArray={this.state.loadingArray}
          loading={this.state.loading}
        />
        <Nav />
        <div className="lazyPortfolio">
          <p className="lazyText">
            This is not a cookie alert but please bear with me as I keep
            improving the site.
          </p>
          <button className="lazyOk" onClick={this.closeAlert}>
            Alright, Gotcha!
          </button>
        </div>
        <div className="getRealScreen">
          <img src={require('../assets/resize.gif')} alt="Get Out!" />
          <p>
            Get a <span className="underline">real screen!</span> Or just rotate
            your device :)
          </p>
        </div>
        <main id="luxy">
          <Hero />
          <About />
          <Work />
          <Footer />
        </main>
      </>
    );
  }
}

export default Home;
