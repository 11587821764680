import React, { Component } from 'react';
import sr from './ScrollReveal';

class Work extends Component {
  componentDidMount = () => {
    const WorkCardOdd = {
      origin: 'bottom',
      duration: 800,
      delay: 200,
      distance: '20%',
      scale: 1,
      easing: 'ease',
      opacity: 1,
      mobile: false,
    };

    const WorkCardEven = {
      origin: 'bottom',
      duration: 800,
      delay: 400,
      distance: '20%',
      scale: 1,
      easing: 'ease',
      opacity: 1,
      mobile: false,
    };

    sr.reveal(this.refs.workCard1, WorkCardOdd);
    sr.reveal(this.refs.workCard2, WorkCardEven);
    sr.reveal(this.refs.workCard3, WorkCardOdd);
    sr.reveal(this.refs.workCard4, WorkCardEven);
    sr.reveal(this.refs.workCard5, WorkCardOdd);
    sr.reveal(this.refs.workCard6, WorkCardEven);
    sr.reveal(this.refs.workCard7, WorkCardOdd);
  };

  render() {
    return (
      <section className="work wrapper" id="work">
        <div className="marquee">
          <div className="marquee-wrap">
            <div className="marquee-title">
              <p className="_w-title wt1">Selected&nbsp;Work&nbsp;&nbsp;</p>
              <p className="_w-title wt1">Selected&nbsp;Work&nbsp;&nbsp;</p>
              <p className="_w-title wt1">Selected&nbsp;Work&nbsp;&nbsp;</p>
              <p className="_w-title wt1">Selected&nbsp;Work&nbsp;&nbsp;</p>
            </div>
          </div>
        </div>

        <div className="row">
          {/* Case Study Number #0 */}
          <a
            className="item notallowed"
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            ref="workCard1">
            <div className="item__img-wrap">
              <div className="item__img item__img--t1"></div>
            </div>
            <div className="item__caption">
              <p className="item__caption-title">GitHub</p>
              <p className="item__caption-copy">Lots of Projects :)</p>
            </div>
          </a>
          {/* Case Study Number #1 */}
          <a
            className="item notallowed"
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            ref="workCard1">
            <div className="item__img-wrap">
              <div className="item__img item__img--t1"></div>
            </div>
            <div className="item__caption">
              <p className="item__caption-title">Google / BASIC ® (NDA)</p>
              <p className="item__caption-copy">(NDA)</p>
            </div>
          </a>
          {/* Case Study Number #2 */}
          <a
            className="item"
            href="https://ig.space/"
            target="_blank"
            rel="noopener noreferrer"
            ref="workCard2">
            <div className="item__img-wrap">
              <div className="item__img item__img--t2"></div>
            </div>
            <div className="item__caption">
              <p className="item__caption-title">Intergalactic</p>
              <p className="item__caption-copy">
                UI/UX, Web Design, Motion Graphics
              </p>
            </div>
          </a>
          {/* Case Study Number #3 */}
          <a
            className="item"
            href="https://www.rumberger.com/"
            target="_blank"
            rel="noopener noreferrer"
            ref="workCard3">
            <div className="item__img-wrap">
              <div className="item__img item__img--t3"></div>
            </div>
            <div className="item__caption">
              <p className="item__caption-title">Rumberger Kirk</p>
              <p className="item__caption-copy">
                UI/UX, Interaction Design, Motion Graphics
              </p>
            </div>
          </a>
          {/* Case Study Number #4 */}
          <a
            className="item"
            href="https://gibbsreg.com/"
            target="_blank"
            rel="noopener noreferrer"
            ref="workCard1">
            <div className="item__img-wrap">
              <div className="item__img item__img--t1"></div>
            </div>
            <div className="item__caption">
              <p className="item__caption-title">Gibbs and Register</p>
              <p className="item__caption-copy">UI/UX, Web Design</p>
            </div>
          </a>
          {/* Case Study Number #5 */}
          <a
            className="item"
            href="https://www.behance.net/gallery/84615959/Curae-Skin-Website"
            target="_blank"
            rel="noopener noreferrer"
            ref="workCard5">
            <div className="item__img-wrap">
              <div className="item__img item__img--t5"></div>
            </div>
            <div className="item__caption">
              <p className="item__caption-title">Curae Skin</p>
              <p className="item__caption-copy">UI/UX, Concept Redesign</p>
            </div>
          </a>
        </div>
      </section>
    );
  }
}

export default Work;
