import React, { Component } from 'react';

class About extends Component {
  componentDidMount() {
    const bigvisionText = document.querySelector('.hover-text');

    bigvisionText.addEventListener('mouseover', this.showImage);
    bigvisionText.addEventListener('mouseleave', this.removeImage);
  }

  showImage = () => {
    const bigvisionImage = document.querySelector('.hoverImg');
    bigvisionImage.style.display = 'block';
  };

  removeImage = () => {
    const bigvisionImage = document.querySelector('.hoverImg');
    bigvisionImage.style.display = 'none';
  };

  render() {
    return (
      <section className="about wrapper" id="about">
        <div className="social_links">
          <div className="marquee">
            <div className="marquee-wrap">
              <div className="marquee-title">
                <p className="_w-title wt1">
                  Stay&nbsp;In&nbsp;Touch&nbsp;&nbsp;
                </p>
                <p className="_w-title wt1">
                  Stay&nbsp;In&nbsp;Touch&nbsp;&nbsp;
                </p>
                <p className="_w-title wt1">
                  Stay&nbsp;In&nbsp;Touch&nbsp;&nbsp;
                </p>
                <p className="_w-title wt1">
                  Stay&nbsp;In&nbsp;Touch&nbsp;&nbsp;
                </p>
              </div>
            </div>
          </div>

          <a
            href="https://dribbble.com/jesussandrea"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Dribbble">
            Dribbble
          </a>
          <a
            href="https://instagram.com/jesussandreas"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram">
            Instagram
          </a>
          <a
            href="https://www.threads.net/@jesussandreas"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Threads">
            Threads
          </a>
        </div>
        <div className="intro">
          <div className="marquee">
            <div className="marquee-wrap">
              <div className="marquee-title">
                <p className="_w-title wt1">
                  Intro&nbsp;&nbsp;Intro&nbsp;&nbsp;
                </p>
                <p className="_w-title wt1">
                  Intro&nbsp;&nbsp;Intro&nbsp;&nbsp;
                </p>
                <p className="_w-title wt1">
                  Intro&nbsp;&nbsp;Intro&nbsp;&nbsp;
                </p>
                <p className="_w-title wt1">
                  Intro&nbsp;&nbsp;Intro&nbsp;&nbsp;
                </p>
              </div>
            </div>
          </div>

          <p className="intro--short">
            I'm a 20something tired year old designer based in SF BA, CA.
            currently working at{' '}
            <span className="hover-text text-underline">GitHub™</span>
            <img
              className="hoverImg"
              src={require('../assets/giphy.gif')}
              alt="GitHub GIF"
            />
            . I believe great design is the result of a deep process
            understanding the problem you’re trying to solve, combined with a
            beautifully executed solution.
          </p>
          <p className="intro--long">
            l love everything that has to do with product & visual design as
            well as delivering extraordinary creative solutions across digital &
            beyond. In my spare time, you can probably find me{' '}
            <del>cooking</del> eating vegetarian food, exploring somewhere, or
            reading a good book but hey, who likes to read long descriptions
            anyways? So let’s jump to what really matters. Let the scrolling
            commence.
          </p>
        </div>
      </section>
    );
  }
}

export default About;
