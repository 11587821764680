import React, { Component } from 'react';
import svgSpinner from '../assets/spinner.svg';
import sr from './ScrollReveal';

class Hero extends Component {
  componentDidMount = () => {
    const innerLine1 = {
      origin: 'bottom',
      duration: 1000,
      delay: 5000,
      distance: '100%',
      scale: 1,
      easing: 'ease',
      opacity: 1,
    };

    const innerLine2 = {
      origin: 'bottom',
      duration: 1000,
      delay: 5400,
      distance: '100%',
      scale: 1,
      easing: 'ease',
      opacity: 1,
    };

    const innerLine3 = {
      origin: 'bottom',
      duration: 1000,
      delay: 5800,
      distance: '100%',
      scale: 1,
      easing: 'ease',
      opacity: 1,
    };

    sr.reveal(this.refs.innerLine1, innerLine1);
    sr.reveal(this.refs.innerLine2, innerLine2);
    sr.reveal(this.refs.innerLine3, innerLine3);
  };

  render() {
    return (
      <section className="hero wrapper" id="hero">
        <div className="hero_container">
          <h1 className="hero--headline o-heading-l hide-medium-up">
            <div className="w-text">
              <div className="w-text__inner t-1" ref="innerLine1">
                Hey, <span className="underline">I’m Jesus.</span> A designer
              </div>
            </div>
            <div className="w-text">
              <div className="w-text__inner t-2" ref="innerLine2">
                {' '}
                based in SF BA, CA. Crafting neat
              </div>
            </div>
            <div className="w-text">
              <div className="w-text__inner t-3" ref="innerLine3">
                user experiences.
              </div>{' '}
            </div>
          </h1>
          <h1 className="hero--headline o-heading-l show-medium-down">
            Hey, <span className="underline">I’m Jesus.</span> A designer based
            in SF BA, CA. Crafting neat user experiences.
          </h1>
          <img
            className="hero--avatar"
            src={require('../assets/avatar@2x.jpg')}
            srcSet={`
                ${require('../assets/avatar@3x.jpg')} 2x, 
                ${require('../assets/avatar@4x.jpg')} 3x
              `}
            alt="Jesus Sandrea Avatar"
          />
          {/* 
            <div className="hero--outlined-box">
              <p className="hero--outlined-box_coords">
                28° 32' 18.0096'' N 81° 22' 45.2424'' W
              </p>
            </div> 
          */}
        </div>
        <div className="hero--contact-cta">
          <a
            href="mailto:hey@jesusssandrea.com"
            className="c-wwu js-anchor-bottom"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Contact">
            <div className="c-wwu__inner">
              <div className="c-wwu__text">
                <div
                  className="js-wwu__text"
                  style={{ transform: 'matrix(1, 0, 0, 1, 0, 0)' }}>
                  Let's talk
                </div>
              </div>
              <div
                className="c-wwu__line js-wwu__line"
                style={{ transform: 'matrix(1, 0, 0, 1, 0, 0)' }}></div>
            </div>
          </a>
        </div>

        <img
          className="c-spinner"
          src={svgSpinner}
          alt="Spinner Kill the Average"
        />
      </section>
    );
  }
}

export default Hero;
