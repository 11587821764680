import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from '../pages/Home';
// import NotFound from './NotFound';

// <BrowserRouter forceRefresh={true}>
const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Home} />
    </Switch>
  </BrowserRouter>
);

export default Router;
