import React, { Component } from 'react';

class Loader extends Component {
  render() {
    if (window.matchMedia('(max-width: 420px)').matches) {
      return (
        <div
          className={this.props.loading ? 'box--loader' : 'box--loader hide'}>
          <div className="text-loading-wrap">
            <h4 className="text-loading">'Sup</h4>
          </div>
          <div className="bg--loader"></div>
        </div>
      );
    } else {
      return (
        <div
          className={this.props.loading ? 'box--loader' : 'box--loader hide'}>
          <div className="text-loading-wrap">
            <h4 className="text-loading">
              {
                this.props.loadingArray[
                  Math.floor(Math.random() * this.props.loadingArray.length)
                ]
              }
            </h4>
          </div>
          <div className="bg--loader"></div>
        </div>
      );
    }
  }
}

export default Loader;
