import React from 'react';

const Footer = () => {
  return (
    <footer className="footer wrapper" id="contact">
      <div className="r-footer">
        <div className="r-footer_c-first">
          <h2 className="o-heading-m">
            Don’t be shy, <br />
            <span>Make the first move.</span>
          </h2>
        </div>
        <div className="r-footer_c-second">
          {/* First Row */}
          <div className="row flex-column">
            <div className="marquee mb-0">
              <div className="marquee-wrap">
                <div className="marquee-title">
                  <p className="_w-title wt1">
                    Stalk&nbsp;Me&nbsp;On&nbsp;&nbsp;
                  </p>
                  <p className="_w-title wt1">
                    Stalk&nbsp;Me&nbsp;On&nbsp;&nbsp;
                  </p>
                  <p className="_w-title wt1">
                    Stalk&nbsp;Me&nbsp;On&nbsp;&nbsp;
                  </p>
                  <p className="_w-title wt1">
                    Stalk&nbsp;Me&nbsp;On&nbsp;&nbsp;
                  </p>
                </div>
              </div>
            </div>

            <div className="f-links">
              <div className="f-links--1">
                <a
                  href="https://dribbble.com/jesussandrea"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Dribbble">
                  Dribbble
                </a>
                <a
                  href="https://www.threads.net/@jesussandreas"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Threads">
                  Threads
                </a>
              </div>
              <div className="f-links--2">
                <a
                  href="https://medium.com/@jesussandrea12"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Medium">
                  Medium
                </a>
                <a
                  href="https://www.behance.net/jesussandrea"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Behance">
                  Behance
                </a>
              </div>
              <div className="f-links--3">
                <a
                  href="https://instagram.com/jesussandreas"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram">
                  Instagram
                </a>
                <a
                  href="https://www.linkedin.com/in/jesussandrea/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Linkedin">
                  Linkedin
                </a>
              </div>
            </div>
          </div>
          {/* Second Row */}
          <div className="row flex-column">
            <p className="_w-title">Start&nbsp;a&nbsp;Conversation</p>
            <a className="footer--email" href="mailto:hey@jesussandrea.com">
              hey@jesussandrea.com
            </a>
          </div>
        </div>
      </div>

      <hr />

      <div className="w-copyright">
        <div className="w-copyright--bye">
          <p>Ok, bye now.</p>
          <img
            src={require('../assets/smiley.svg')}
            alt="Smiley Face - Brand"
          />
        </div>
        <p className="w-copyright--name">© 20something — Jesus Sandrea</p>
      </div>
    </footer>
  );
};

export default Footer;
