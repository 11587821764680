import React, { Component } from 'react';
import scrollLock from 'scroll-lock';

class Nav extends Component {
  state = {
    menuOpened: false,
  };

  aboutScroll() {
    const posY = document.getElementById('about').offsetTop;
    window.scrollTo(0, posY);
  }

  workScroll() {
    const posY = document.getElementById('work').offsetTop;
    window.scrollTo(0, posY);
  }

  contactScroll() {
    const posY = document.getElementById('contact').offsetTop;
    window.scrollTo(0, posY);
  }

  // menu
  aboutRes = () => {
    this.aboutScroll();
    this.handleMenu();
  };

  workRes = () => {
    this.workScroll();
    this.handleMenu();
  };

  contactRes = () => {
    this.contactScroll();
    this.handleMenu();
  };

  handleMenu = () => {
    const menu = document.querySelector('.menu--responsive');
    const bgDimming = document.querySelector('.bg_dimming');
    const navLogo = document.querySelector('.nav--logo p');
    const topLine = document.querySelector('.burger_menu-top');
    const topBottom = document.querySelector('.burger_menu-bottom');

    // menu links
    const aboutLink = document.querySelector('#aboutLink');
    const workLink = document.querySelector('#workLink');
    const contactLink = document.querySelector('#contactLink');

    const menuDivider = document.querySelector('.menu--responsive_divider');
    const menuFooter = document.querySelector('.menu--responsive_footer');

    if (this.state.menuOpened === false) {
      // turn to true
      this.setState({ menuOpened: true });

      // set to active
      menu.classList.add('is-active');
      menu.classList.remove('removed');

      // bgDimming
      bgDimming.classList.add('is-active');
      bgDimming.style.visibility = 'visible';

      // set to dark
      topLine.classList.remove('darkMode');
      topBottom.classList.remove('darkMode');
      navLogo.classList.remove('darkMode');

      // animation lines
      topLine.classList.add('is-active');
      topBottom.classList.add('is-active');

      menuDivider.classList.remove('is-hidden');
      menuDivider.classList.add('is-visible');

      menuFooter.classList.remove('is-hidden');
      menuFooter.classList.add('is-visible');

      aboutLink.classList.remove('isHidden');
      workLink.classList.remove('isHidden');
      contactLink.classList.remove('isHidden');
      // animate links
      aboutLink.classList.add('isVisible');
      workLink.classList.add('isVisible');
      contactLink.classList.add('isVisible');
      // setTimeout(() => {
      // }, 800);
      // setTimeout(() => {
      // }, 1200);
      // setTimeout(() => {
      // }, 1600);

      // disable scroll
      scrollLock.disablePageScroll();
    } else if (this.state.menuOpened === true) {
      // turn to false
      this.setState({
        menuOpened: false,
      });
      // enable scroll
      scrollLock.enablePageScroll();

      // animation lines
      topLine.classList.remove('is-active');
      topBottom.classList.remove('is-active');

      // remove menu class
      menu.classList.remove('is-active');
      menu.classList.add('removed');

      // remove dimming
      bgDimming.classList.remove('is-active');

      // remove move up class
      aboutLink.classList.remove('isVisible');
      workLink.classList.remove('isVisible');
      contactLink.classList.remove('isVisible');

      contactLink.classList.add('isHidden');
      workLink.classList.add('isHidden');
      aboutLink.classList.add('isHidden');

      menuDivider.classList.remove('is-visible');
      menuDivider.classList.add('is-hidden');

      menuFooter.classList.remove('is-visible');
      menuFooter.classList.add('is-hidden');

      setTimeout(() => {
        bgDimming.style.visibility = 'hidden';
      }, 800);

      // set to dark again
      if (document.querySelector('body').classList.contains('changeColor')) {
        topLine.classList.add('darkMode');
        topBottom.classList.add('darkMode');
        navLogo.classList.add('darkMode');
      }
    }
  };

  render() {
    return (
      <nav className="nav">
        <div className="nav--wrapper">
          <div className="nav--logo">
            <p className="nav--logo">JS ©</p>
          </div>
          <ul className="nav--link hover-effect">
            <li className="nav--link-item">
              <button data-hover="About" onClick={this.aboutScroll}>
                About
              </button>
            </li>
            <li className="nav--link-item">
              <button data-hover="Work" onClick={this.workScroll}>
                Work
              </button>
            </li>
            <li className="nav--link-item">
              <button data-hover="Contact" onClick={this.contactScroll}>
                Contact
              </button>
            </li>
          </ul>
          <button className="burger_menu" onClick={this.handleMenu}>
            <div className="burger_menu-top"></div>
            <div className="burger_menu-bottom"></div>
          </button>
        </div>

        {/* menu responsive */}
        <div className="menu--responsive">
          <ul className="menu--responsive_items">
            <div className="menu_item-wrap">
              <li id="aboutLink" onClick={this.aboutRes}>
                <button>About</button>
              </li>
            </div>
            <div className="menu_item-wrap">
              <li id="workLink" onClick={this.workRes}>
                <button>Work</button>
              </li>
            </div>
            <div className="menu_item-wrap">
              <li id="contactLink" onClick={this.contactRes}>
                <button>Contact</button>
              </li>
            </div>
          </ul>
          <hr className="menu--responsive_divider" />
          <div className="menu--responsive_footer">
            <h4>Start a conversation</h4>
            <a
              className="c-mail_menu"
              href="mailto:hey@jesussandrea.com"
              target="_blank"
              rel="noopener noreferrer">
              hey@jesussandrea.com
            </a>
          </div>
        </div>
        <div className="bg_dimming"></div>
      </nav>
    );
  }
}

export default Nav;
